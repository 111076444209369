import React from 'react'

import './index.scss'

export const Resume = () => {
  return (
    <div>
      <h1 className="resume-title">Hello </h1>
      <h2 className="resume-soon">
       Test Case
      </h2>
    </div>
  )
}
